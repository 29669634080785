import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React, { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";

let linkArray = [
  {
    route: `/`,
    title: `Home`,
  },
  {
    route: `/services/`,
    title: `Services`,
  },
  {
    route: `/wij-zijn-gladior/`,
    title: `Wij zijn Gladior`,
  },
  {
    route: `/cases/`,
    title: `Cases`,
  },
  {
    route: `/partners/`,
    title: `Partners`,
  },
  {
    route: `/jobs/`,
    title: `Jobs`,
  },
  {
    route: `/contact/`,
    title: `Contact`,
  },
];

function Header({
  isHomePage,
  noPhotoBackground = false,
  absolute = true,
  darkBackground = false,
}) {
  const [isExpanded, toggleExpansion] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      gladiorLogoBlack: file(relativePath: { eq: "gladior-logo-black.png" }) {
        childImageSharp {
          fixed(height: 50) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      gladiorLogoWhite: file(relativePath: { eq: "gladior-logo-white.png" }) {
        childImageSharp {
          fixed(height: 50) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  let { site, logo } = data;

  const isActive = ({ isCurrent }) => {
    return isCurrent
      ? {
          onClick: () => {
            toggleExpansion(!isExpanded);
          },
        }
      : null;
  };

  const ExactNavLink = (props) => <Link getProps={isActive} {...props} />;

  useEffect(() => {
    var id = "b0ace25e-1ad2-11eb-87ed-0242ac130002";
    var ci_search = document.createElement("script");
    ci_search.type = "text/javascript";
    ci_search.async = true;
    ci_search.src = "https://cse.expertrec.com/api/js/ci_common.js?id=" + id;
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(ci_search, s);
  }, []);

  return (
    <React.Fragment>
      <SlidingPanel
        backdropClicked={() => {
          toggleExpansion(!isExpanded);
        }}
        isOpen={isExpanded}
        size={100}
        type={`top`}
      >
        <div className={`bg-gladior-black  h-full text-white`}>
          <div className="flex flex-wrap items-center justify-end p-4">
            <button
              aria-label={`Sluit menu`}
              className="hamburger hamburger--collapse is-active ring-opacity-0 "
              onClick={() => toggleExpansion(!isExpanded)}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner bg-gladior-pink"></span>
              </span>
            </button>
          </div>
          <div className="flex flex-col p-4 sm:p-10 ">
            <div className={`flex flex-col mb-40 text-white`}>
              {[...linkArray].map((link) => (
                <Link
                  activeClassName="font-extrabold text-white"
                  className="text-3xl font-light text-gray-100"
                  key={link.title}
                  onClick={() => {
                    setTimeout(function () {
                      toggleExpansion(!isExpanded);
                    }, 500);
                  }}
                  to={link.route}
                >
                  {link.title}
                </Link>
              ))}
            </div>
            <p className="text-xl font-semibold">Let's go on tour together!</p>

            <a
              className="text-xl no-underline text-gladior-pink hover:underline  hover:text-white"
              href="tel:+31538507090"
            >
              +31 (0)53 850 70 90
            </a>
          </div>
        </div>
      </SlidingPanel>
      <header
        className={
          noPhotoBackground === true
            ? `bg-gladior-white`
            : absolute === true
            ? `z-50 absolute top-0 left-0 right-0`
            : darkBackground === true
            ? `bg-gladior-black`
            : ``
        }
      >
        <div className="flex flex-wrap items-center justify-between container mx-auto px-4 p-4 md:p-8">
          <Link className="flex items-center no-underline" to="/">
            <Img
              alt="Logo van Gladior B.V."
              className="h-12"
              fadeIn={false}
              fixed={
                noPhotoBackground === true
                  ? data.gladiorLogoBlack.childImageSharp.fixed
                  : data.gladiorLogoWhite.childImageSharp.fixed
              }
              loading="eager"
              title="Logo van Gladior B.V."
            />
          </Link>

          <button
            aria-label={`Open menu`}
            className={`hamburger ring-opacity-0  block md:hidden border border-white flex items-center px-3 py-2 rounded ${
              noPhotoBackground === true ? `text-gladior-black` : `text-white`
            }`}
            onClick={() => toggleExpansion(!isExpanded)}
          >
            {isExpanded === false && (
              <span className="hamburger-box">
                <span
                  className={`hamburger-inner ${
                    noPhotoBackground === true
                      ? `hamburger-inner-black`
                      : `hamburger-inner-white`
                  } bg-gladior-pink`}
                ></span>
              </span>
            )}
          </button>

          <nav
            className={`hidden md:block md:flex md:items-center w-full md:w-auto ${
              noPhotoBackground === true ? `text-gladior-black` : `text-white`
            } `}
          >
            {linkArray.map((link) => (
              <Link
                className="block md:inline-block mt-4 md:mt-0 md:ml-6 no-underline hover:underline hover:text-gladior-pink"
                key={link.title}
                to={link.route}
              >
                {link.title}
              </Link>
            ))}
            <ci-search class="mt-4 md:mt-0 md:ml-6"> </ci-search>
          </nav>
        </div>
      </header>
    </React.Fragment>
  );
}

export default Header;
