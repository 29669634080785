import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";

let linkArray = [
  // {
  //   route: `/`,
  //   title: `Cases`
  // },
  {
    route: `/`,
    title: `Home`,
  },
  {
    route: `/services/`,
    title: `Services`,
  },
  {
    route: `/wij-zijn-gladior/`,
    title: `Wij zijn Gladior`,
  },
  {
    route: `/cases/`,
    title: `Cases`,
  },
  {
    route: `/partners/`,
    title: `Partners`,
  },
  {
    route: `/jobs/`,
    title: `Jobs`,
  },
  {
    route: `/contact/`,
    title: `Contact`,
  },
];

let linkArraySitemap = [
  {
    route: `/8-redenen-muziek/`,
    title: `Waarom muziek?`,
  },
  {
    route: `/archief/`,
    title: `Archief`,
  },
  {
    route: `/privacy/`,
    title: `Privacy Statement`,
  },
  {
    route: `/voorwaarden/`,
    title: `Algemene voorwaarden`,
  },
];

function Footer({ isDark = false, showPartners = false, isDarker = false }) {
  const data = useStaticQuery(
    graphql`
      query {
        gladiorLogoBlack: file(relativePath: { eq: "gladior-logo-black.png" }) {
          childImageSharp {
            fixed(height: 50) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
        gladiorLogoWhite: file(relativePath: { eq: "gladior-logo-white.png" }) {
          childImageSharp {
            fixed(height: 50) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
        spotler: file(relativePath: { eq: "wit-spotler-logo.png" }) {
          childImageSharp {
            fixed(height: 35, grayscale: true) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }

        lightspeed: file(relativePath: { eq: "wit-lightspeed-logo.png" }) {
          childImageSharp {
            fixed(height: 35, grayscale: true) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }

        indenty: file(relativePath: { eq: "indenty.png" }) {
          childImageSharp {
            fixed(height: 35, grayscale: true) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }

        googlePartner: file(relativePath: { eq: "wit-google-partner.png" }) {
          childImageSharp {
            fixed(height: 35, grayscale: true) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }

        copernica: file(relativePath: { eq: "copernica.png" }) {
          childImageSharp {
            fixed(height: 35, grayscale: true) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }

        channable: file(relativePath: { eq: "wit-channable-logo.png" }) {
          childImageSharp {
            fixed(height: 35, grayscale: true) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }

        microsoft: file(relativePath: { eq: "wit-microsoft-logo.png" }) {
          childImageSharp {
            fixed(height: 35, grayscale: true) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }

        ccv: file(relativePath: { eq: "wit-ccv-logo.png" }) {
          childImageSharp {
            fixed(height: 35, grayscale: true) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }

        afas: file(relativePath: { eq: "wit-afas-logo.png" }) {
          childImageSharp {
            fixed(height: 35, grayscale: true) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }

        facebook: file(relativePath: { eq: "pink-facebook.png" }) {
          childImageSharp {
            fixed(height: 35) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
        instagram: file(relativePath: { eq: "pink-instagram.png" }) {
          childImageSharp {
            fixed(height: 35) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
        twitter: file(relativePath: { eq: "pink-twitter.png" }) {
          childImageSharp {
            fixed(height: 35) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
        linkedin: file(relativePath: { eq: "pink-linkedin.png" }) {
          childImageSharp {
            fixed(height: 35) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
    `
  );

  const {
    spotler,
    lightspeed,
    indenty,
    googlePartner,
    copernica,
    channable,
    ccv,
    afas,
    microsoft,
    linkedin,
    facebook,
    instagram,
    twitter,
  } = data;

  return (
    <footer
      className={`${
        isDarker === true ? `bg-gladior-dark` : `bg-gladior-black`
      } text-white  footer bg-secondary pt-12  relative `}
    >
      <div className="container mx-auto p-4">
        <div className="w-full ">
          <Img fixed={data.gladiorLogoWhite.childImageSharp.fixed} />
        </div>
        <div className="flex mb-4 flex-wrap">
          <div className="w-full sm:w-1/2 lg:w-1/4">
            <div className="text-sm text-gladior-pink  mb-4 tracking-wide text-sm  font-bold pt-0 mt-4 md:mt-4 md:pt-4 ">
              Bezoek ons
            </div>
            <p className="font-light text-sm leading-loose text-white">
              Boddenkampsingel 87,<br></br> 7514 AP Enschede
            </p>
            <div className="text-sm text-gladior-pink mb-4  tracking-wide text-sm  font-bold pt-0 mt-4 md:mt-14md:pt-4">
              Bel ons
            </div>
            <p className="text-sm">
              <a
                aria-label="Algemene telefoonnummer"
                className="font-light no-underline text-white hover:underline  hover:text-gladior-pink"
                href="tel:+31538507090"
              >
                +31 (0)53 850 70 90
              </a>
            </p>
            <div className="text-sm text-gladior-pink  mb-4 tracking-wide text-sm  font-bold pt-0 mt-4 md:mt-4 md:pt-4">
              Vragen over vacatures
            </div>
            <p className="text-sm">
              <a
                aria-label="Vragen over vacatures telefoonnummer"
                className="font-light no-underline text-white hover:underline  hover:text-gladior-pink"
                href="tel:+31538507095"
              >
                +31 (0)53 850 70 95
              </a>
            </p>
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/4">
            <div className="mb-4 pt-4 mt-4 md:mt-4">
              <Link
                className="text-sm text-gladior-yellow font-bold hover:text-gladior-pink  tracking-wide"
                to={`/services/afas/`}
              >
                AFAS Profit
              </Link>
            </div>
            <p className="text-sm">
              <Link
                aria-label="AFAS OutSite"
                className="font-light no-underline text-white leading-loose	 hover:underline  hover:text-gladior-pink"
                to={`/services/afas-outsite/`}
              >
                AFAS OutSite
              </Link>
              <br></br>
              <Link
                aria-label="AFAS InSite"
                className="font-light no-underline text-white leading-loose	 hover:underline  hover:text-gladior-pink"
                to={`/services/afas-insite/`}
              >
                AFAS InSite
              </Link>
            </p>

            <div className="mb-4 pt-4 mt-4 md:mt-4">
              <Link
                className="text-sm text-gladior-yellow font-bold hover:text-gladior-pink  tracking-wide"
                to={`/services/afas/`}
              >
                AFAS koppelingen
              </Link>
            </div>
            <p className="text-sm">
              <Link
                aria-label="AFAS Magento koppeling"
                className="font-light no-underline text-white leading-loose	 hover:underline  hover:text-gladior-pink"
                to={`/services/magento-afas-profit-koppeling/`}
              >
                AFAS - Magento koppeling
              </Link>

              <br></br>
              <Link
                aria-label="AFAS koppelingen webshop"
                className="font-light no-underline text-white leading-loose	 hover:underline  hover:text-gladior-pink"
                to={`/services/afas/`}
              >
                AFAS Lightspeed, <br></br>
                CCV Shop & Woocommerce
              </Link>
              <br></br>
              <Link
                aria-label="AFAS Mailchimp"
                className="font-light no-underline text-white leading-loose	 hover:underline  hover:text-gladior-pink"
                to={`/services/mailchimp-afas-profit-koppeling/`}
              >
                AFAS Mailchimp
              </Link>
              <br></br>
              <Link
                aria-label="AFAS vacaturefeed"
                className="font-light no-underline text-white leading-loose	 hover:underline  hover:text-gladior-pink"
                to={`/services/afas-vacature-feed/`}
              >
                AFAS XML cursus en vacature feed
              </Link>
            </p>
          </div>

          <div className="w-full sm:w-1/2 lg:w-1/4">
            <div className="mb-4 pt-4 mt-4 md:mt-4">
              <Link
                className="text-sm text-gladior-yellow font-bold hover:text-gladior-pink  tracking-wide"
                to={`/services/business-consultancy/`}
              >
                Business Consultancy
              </Link>
            </div>
            <p className="text-sm">
              <Link
                aria-label="Web Presence Performance Scan"
                className="font-light no-underline text-white leading-loose	 hover:underline  hover:text-gladior-pink"
                to={`/services/web-presence-performance-scan/`}
              >
                Web Presence Performance Scan
              </Link>
              <br></br>
              <Link
                aria-label="Contactstrategie Scan"
                className="font-light no-underline text-white leading-loose	 hover:underline  hover:text-gladior-pink"
                to={`/services/contactstrategie-scan/`}
              >
                Contactstrategie Scan
              </Link>
              <br></br>
              <Link
                aria-label="Digital Marketing Scan"
                className="font-light no-underline text-white leading-loose	 hover:underline  hover:text-gladior-pink"
                to={`/services/digital-marketing-scan/`}
              >
                Digital Marketing Scan
              </Link>
            </p>
            <div className="mb-4 pt-4 mt-4 md:mt-4">
              <Link
                className="text-sm text-gladior-yellow font-bold hover:text-gladior-pink  tracking-wide"
                to={`/services/optimizing/`}
              >
                Optimize
              </Link>
            </div>
            <p className="text-sm">
              <Link
                aria-label="Dashboarding"
                className="font-light no-underline text-white leading-loose	 hover:underline  hover:text-gladior-pink"
                to={`/services/data-dashboards/`}
              >
                Dashboarding
              </Link>
              <br></br>
              <Link
                aria-label="Zoekmachine optimalisatie (SEO)"
                className="font-light no-underline text-white leading-loose	 hover:underline  hover:text-gladior-pink"
                to={`/services/zoekmachine-optimalisatie-seo/`}
              >
                Zoekmachine optimalisatie (SEO)
              </Link>
              <br></br>
              <Link
                aria-label="Conversie optimalisatie"
                className="font-light no-underline text-white leading-loose	 hover:underline  hover:text-gladior-pink"
                to={`/services/conversie-optimalisatie/`}
              >
                Conversie optimalisatie
              </Link>
            </p>
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/4">
            <div className="mb-4 pt-4 mt-4 md:mt-4">
              <Link
                className="text-sm text-gladior-yellow font-bold hover:text-gladior-pink  tracking-wide"
                to={`/services/web-design/`}
              >
                Web & Design
              </Link>
            </div>
            <p className="text-sm">
              <Link
                aria-label="Magento webshop icm AFAS"
                className="font-light no-underline text-white leading-loose	 hover:underline  hover:text-gladior-pink"
                to={`/services/magento-webshop/`}
              >
                Magento webshop icm AFAS
              </Link>
              <br></br>
              <Link
                aria-label="CCV Shop icm Afas"
                className="font-light no-underline text-white leading-loose	 hover:underline  hover:text-gladior-pink"
                to={`/services/ccv-shop/`}
              >
                CCV Shop icm AFAS
              </Link>
              <br></br>
              <Link
                aria-label="Lightspeed webshop icm Afas"
                className="font-light no-underline text-white leading-loose	 hover:underline  hover:text-gladior-pink"
                to={`/services/lightspeed-webshop/`}
              >
                Lightspeed webshop icm AFAS
              </Link>
              <br></br>
              <Link
                aria-label="Webdesign &amp; development"
                className="font-light no-underline text-white leading-loose	 hover:underline  hover:text-gladior-pink"
                to={`/services/webshops/`}
              >
                Webdesign & development
              </Link>
              <br></br>
            </p>
            <div className="flex justify-start items-center mt-8">
              <a
                aria-label="Gladior Linkedin"
                className="mr-4"
                href="https://www.linkedin.com/company/gladior/"
                rel="noreferrer noopener"
                target="_blank"
              >
                <Img fixed={linkedin.childImageSharp.fixed} />
              </a>
              <a
                aria-label="Gladior Instagram"
                className="mr-4"
                href="https://www.instagram.com/gladior.digital.marketing/"
                rel="noreferrer noopener"
                target="_blank"
              >
                <Img fixed={instagram.childImageSharp.fixed} />
              </a>
              <a
                aria-label="Gladior twitter"
                className="mr-4"
                href="https://twitter.com/gladior"
                rel="noreferrer noopener"
                target="_blank"
              >
                <Img fixed={twitter.childImageSharp.fixed} />
              </a>
              <a
                aria-label="Gladior Facebook"
                href="https://www.facebook.com/GladiorBV"
                rel="noreferrer noopener"
                target="_blank"
              >
                <Img fixed={facebook.childImageSharp.fixed} />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto ">
        <div
          className={`mt-16 border-t ${
            isDark === true ? `border-gray-700` : `border-gray-200`
          } flex flex-col items-center`}
        >
          {showPartners === true && isDark === false && (
            <section className="bg-gladior-black w-full">
              <div className="container mx-auto py-6 px-0 text-white flex  ">
                <div className=" flex flex-col sm:flex-row flex-wrap  w-full justify-center lg:justify-between items-center">
                  <p className="  tracking-wide text-sm text-gladior-yellow font-bold w-full text-center mb-4 lg:w-1/8">
                    Our collaborations
                  </p>
                  <div className="ml-0  mb-4 sm:mb-0">
                    <Img fixed={afas.childImageSharp.fixed} />
                  </div>
                  <div className="ml-0 sm:ml-10 mb-4 sm:mb-0">
                    <Img fixed={googlePartner.childImageSharp.fixed} />
                  </div>
                  <div className="ml-0 sm:ml-10 mb-4 sm:mb-0">
                    <Img fixed={channable.childImageSharp.fixed} />
                  </div>

                  <div className="ml-0 sm:ml-10 mb-4 sm:mb-0">
                    <Img fixed={ccv.childImageSharp.fixed} />
                  </div>
                  <div className="ml-0 sm:ml-10 mb-4 sm:mb-0">
                    <Img fixed={lightspeed.childImageSharp.fixed} />
                  </div>
                  <div className="ml-0 sm:ml-10 mb-4 sm:mb-0">
                    <Img fixed={spotler.childImageSharp.fixed} />
                  </div>
                  <div className="ml-0 sm:ml-10 mb-4 sm:mb-0">
                    <Img fixed={microsoft.childImageSharp.fixed} />
                  </div>
                </div>
              </div>
            </section>
          )}

          <div className="sm:w-2/3 text-center py-6">
            <p className="text-sm mb-2 text-white">
              <a aria-label="Archief" href="/archief/">
                Archief
              </a>
              {` `}/{` `}
              <a aria-label="Privacy statement" href="/privacy/">
                Privacy statement
              </a>
              {` `}/{` `}
              <a aria-label="Algemene voorwaarden" href="/voorwaarden/">
                Algemene voorwaarden
              </a>
              {` `}/ © {new Date().getFullYear()} Gladior B.V. All rights
              reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
