import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { getCLS, getFCP, getFID, getLCP, getTTFB } from "web-vitals";

function sendToGoogleTagmanager({ name, delta, id }) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "vitals",
    webVitals: {
      value: Math.round(name === "CLS" ? delta * 1000 : delta),
      name: name,
      id: id,
    },
  });
}

function SEO({
  title,
  description,
  lang,
  image,
  pathname,
  isArticle,
  articleMeta,
}) {
  //useEffect
  useEffect(() => {
    getCLS(sendToGoogleTagmanager);
    getFCP(sendToGoogleTagmanager);
    getFID(sendToGoogleTagmanager);
    getLCP(sendToGoogleTagmanager);
    getTTFB(sendToGoogleTagmanager);
  }, []); //Pass Array as second argument

  // eslint-disable-next-line no-use-before-define
  const { site } = useStaticQuery(query);

  const {
    buildTime,
    siteMetadata: {
      pathPrefix,
      siteUrl,
      defaultTitle,
      titleTemplate: titleTemplateInput,
      defaultDescription,
      defaultImage,
      siteLanguage,
      headline,
      author,
      twitter,
    },
  } = site;

  // Use global defaults whenever there's no prop
  const seo = {
    lang: lang || siteLanguage,
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${pathPrefix}${image || defaultImage}`,
    url: `${siteUrl}${pathname || ""}`,
  };

  let titleTemplate = seo.title.trim().endsWith("Gladior")
    ? "%s"
    : titleTemplateInput;

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')
  // Copied from https://github.com/LeKoArts/gatsby-starter-prismic/blob/master/src/components/SEO/SEO.jsx

  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: siteUrl,
    headline,
    inLanguage: siteLanguage,
    mainEntityOfPage: siteUrl,
    description: defaultDescription,
    name: defaultTitle,
    datePublished: "2019-10-01T10:30:00+01:00",
    dateModified: buildTime,
    image: {
      "@type": "ImageObject",
      url: seo.image,
    },
  };

  // Initial breadcrumb list
  const itemListElement = [
    {
      "@type": "ListItem",
      item: {
        "@id": siteUrl,
        name: "Homepage",
      },
      position: 1,
    },
  ];

  let schemaArticle = null;
  if (isArticle) {
    schemaArticle = {
      "@context": "http://schema.org",
      "@type": "Article",
      author: {
        "@type": "Person",
        name: author,
      },
      copyrightHolder: {
        "@type": "Person",
        name: author,
      },
      copyrightYear: "2019",
      creator: {
        "@type": "Person",
        name: author,
      },
      publisher: {
        "@type": "Organization",
        name: author,
        logo: {
          "@type": "ImageObject",
          url: seo.image,
        },
      },
      datePublished: articleMeta.datePublished,
      dateModified: articleMeta.dateModified,
      description: seo.description,
      headline: seo.title,
      inLanguage: siteLanguage,
      url: seo.url,
      name: seo.title,
      image: {
        "@type": "ImageObject",
        url: seo.image,
      },
      mainEntityOfPage: seo.url,
    };
    // Push current blogpost into breadcrumb list
    itemListElement.push({
      "@type": "ListItem",
      item: {
        "@id": seo.url,
        name: seo.title,
      },
      position: 2,
    });
  }

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    description: "Breadcrumbs list",
    name: "Breadcrumbs",
    itemListElement,
  };

  return (
    <Helmet
      title={title}
      defaultTitle={seo.title}
      titleTemplate={titleTemplate}
    >
      <html lang={seo.lang} />
      <meta name="description" content={seo.description} />
      {/* OpenGraph tags */}
      <meta property="og:url" content={seo.url} />
      <meta property="og:title" content={seo.title} />
      <meta
        property="og:description"
        content={seo.description.substring(0, 65)}
      />
      <meta property="og:image" content={seo.image} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="nl_NL" />
      {/* Twitter tags */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={seo.title} />
      <meta
        name="twitter:description"
        content={seo.description.substring(0, 125)}
      />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:site" content={twitter} />
      <meta name="twitter:site:id" content={"18979214"} />

      {twitter && <meta name="twitter:creator" content={twitter} />}
      {/* schema.org data
          Always insert breadcrumbs, but article schema only if `articleMeta` is passed
      */}
      <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      {!isArticle && (
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgWebPage)}
        </script>
      )}
      {isArticle &&
        articleMeta &&
        articleMeta.datePublished &&
        articleMeta.dateModified && (
          <script type="application/ld+json">
            {JSON.stringify(schemaArticle)}
          </script>
        )}
    </Helmet>
  );
}

SEO.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  isArticle: PropTypes.bool,
  articleMeta: PropTypes.shape({
    datePublished: PropTypes.string,
    dateModified: PropTypes.string,
  }),
};

SEO.defaultProps = {
  lang: `nl`,
  title: null,
  description: null,
  image: null,
  pathname: null,
  isArticle: false,
  articleMeta: null,
};

export default SEO;

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        pathPrefix
        siteUrl
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        defaultImage: image
        siteLanguage
        headline
        author
        twitter
      }
    }
  }
`;
